import React, { useCallback } from 'react'
import './editor.css'
import { debounce } from 'lodash';
import ReactQuill, { Quill } from 'react-quill'
import { VideoBlot } from './VideoBlot.jsx';
import { useEffect, useMemo, useState, useRef } from 'react'
import 'react-quill/dist/quill.snow.css';
import { config} from './config.jsx'
import { Box, Button, Input, Spinner, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
//import { cdnUri } from '../../../../config/index.js';

const icons = Quill.import('ui/icons')

VideoBlot.blotName = 'insertVideo'
VideoBlot.tagName = 'video'
Quill.register(VideoBlot)

const Parchment = Quill.import('parchment');

let FontClass = new Parchment.Attributor.Class('font', 'ql-font', {
  scope: Parchment.Scope.INLINE,
  whitelist: ['sans-serif', 'serif', 'monospace']
});
Quill.register(FontClass, true);

class CustomImageBlot extends ReactQuill.Quill.import('formats/image') {
    static create(value) {
        if (typeof value == 'string') {
            return super.create(value);
          } else {
            return value;
          }
    }
  
    static value(domNode) {
        return domNode;
      }
  }
  ReactQuill.Quill.register('formats/image', CustomImageBlot)

class CustomBlot extends Quill.import('blots/block/embed') {
    static create(value) {
      const node = super.create();
      node.innerHTML = value;
      return node;
    }
  
    static value(node) {
      return node.innerHTML;
    }
  }
  
  CustomBlot.blotName = 'custom';
  CustomBlot.tagName = 'div';
  Quill.register(CustomBlot);

icons["undo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
<title>Undo</title>
</svg>`
icons["redo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
<title>Redo</title>
</svg>`
//icons.undo = config.icons.undo
//icons.redo = config.icons.redo
icons.bold = config.icons.bold
icons.italic = config.icons.italic
icons.underline = config.icons.underline
icons.strike = config.icons.strike
icons.blockquote = config.icons.blockquote
icons.list.ordered = config.icons.ordered
icons.list.bullet = config.icons.bullet
icons.script.sub = config.icons.sub
icons.script.super = config.icons.super
icons.color = config.icons.color
icons.background = config.icons.background
icons.image = config.icons.image
icons.video = config.icons.video
icons.link = config.icons.link
icons.attachment = config.icons.attachment
icons['code-block'] = config.icons.codeBlock
icons['indent']['-1'] = config.icons.indentBackward
icons['indent']['+1'] = config.icons.indentForward
let defualtModules =  config.defualtToolbar

const QuillEditor = (props) => {
    const cdnUri = props.tenant.cdnUri
    //Image States
    const [showImageUpload,setShowImageUpload] = useState(false)
    const [showImageEmbaded,setShowImageEmbaded] = useState(false)
    const [imageModel,setImageModel] = useState(false)
    const [imageUrl, setImageUrl] = useState(null)
    const [imageFile,setImageFile] = useState(null)
    const [imageUploadErr,setImageUploadErr] = useState(false)
    const [imageHeight,setImageHeight] = useState(null)
    const [imageWidth,setImageWidth] = useState(null)
    const [imageAlt,setImageAlt] = useState("")
    const [imageUrlErr,setImageUrlErr] = useState(false)
    const [currentUploadType,setCurrentUploadType] = useState("")
    const [isLoadingEditor,setIsLoadingEditor] = useState(false)
    const [imageUrlText,setImageUrlText] = useState("")
    //Video States
    const [videoModel,setVideoModel] = useState(false)
    const [showVideoUpload,setShowVideoUpload] = useState(true)
    const [showVideoEmbaded,setShowVideoEmbaded] = useState(false)
    const [videoFile, setVideoFile] = useState(null)
    const [videoUrlText,setVideoUrlText] = useState("")
    const [videoUrlErr,setVideoUrlErr] = useState(false)
    const [isVideo,setIsVideo] = useState(false)
    //Attachment States
    const [attachmentFile,setAttachmentFile] = useState("")
    const [attachmentFileName,setAttachmentFileName] = useState('')
    const [attachmentModel,setAttachmentModel] = useState(false)
    const [isAttachment,setIsAttachment] = useState(false)
    const [attachmentErr,setAttachmentErr] = useState(false)
    const [attachmentErrMsg,setAttachmentErrMsg] = useState('')
    //Link States
    const [linkModel,setLinkModel] = useState(false)
    const [linkTitle, setLinkTitle] = useState('');
    const [linkTarget, setLinkTarget] = useState('');
    const [editorValue, setEditorValue] = useState(props?.value || '')
    const [edit,setEdit] = useState(false)
    const quillRef = useRef(null);
    const editorRef = useRef();
    const imageModalRef = useRef(null);
    const videoModalRef = useRef(null);
    const attachmentModalRef = useRef(null);
    const linkModalRef = useRef(null);

    //Attachment Icons
    const iconList = {
        txt: `${cdnUri}/cdn/txt.png`,
        pdf: `${cdnUri}/cdn/pdf.png`,
        xls: `${cdnUri}/cdn/xlsx.png`,
        xlsx: `${cdnUri}/cdn/xlsx.png`,
        doc: `${cdnUri}/cdn/word.png`,
        docx: `${cdnUri}/cdn/word.png`,
        ppt: `${cdnUri}/cdn/ppt.png`,
        csv: `${cdnUri}/cdn/cvs.png`,
        tsv: `${cdnUri}/cdn/txt.png`,
        defaultVideo:`${cdnUri}/cdn/thumbnail.png`,
    }

    useEffect(() => {
        if (props.defaultEditorState) {
            setEdit(true);
        }
    }, [])

    useEffect(() => {
        const handleClick = (event) => {
            if (imageModel && imageModalRef.current && !imageModalRef.current.contains(event.target)) {
                setImageModel(false);
            }
            if (videoModel && videoModalRef.current && !videoModalRef.current.contains(event.target)) {
                setVideoModel(false);
            }
            if (attachmentModel && attachmentModalRef.current && !attachmentModalRef.current.contains(event.target)) {
                setAttachmentModel(false);
            }
            if (linkModel && linkModalRef.current && !linkModalRef.current.contains(event.target)) {
                setLinkModel(false);
            }
        };
    
        if (imageModel || videoModel || attachmentModel || linkModel) {
            document.addEventListener('mousedown', handleClick);
        }

        return () => {
          document.removeEventListener('mousedown', handleClick)
        }
      }, [imageModel, videoModel, attachmentModel, linkModel])

    const myUndo = () => {
        let myEditor = quillRef?.current?.getEditor();
        return myEditor.history.undo();
    }

    const isValidImageUrl = (url) => {
        let  regexhttps = /^https:\/\//i
        if ( regexhttps.test(url) ) { 
            const regex = /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png|jpeg|bmp|svg|ico|webp|tif|tiff)/gi
            return url.match(regex) 
        } else {
            return false
        }
    }

    const myRedo = () => {
        let myEditor = quillRef?.current?.getEditor();
        return myEditor.history.redo();
    }

    //Image Upload Functions
    const openImageFileUpload =  useCallback(() =>{
        setShowImageUpload(true)
        setShowImageEmbaded(false)
    },[])
    const showImageUploadModal = () => {
        setImageModel(true)
        openImageFileUpload()
    }
    const openImageUploadText = () => {
        setShowImageUpload(false)
        setShowImageEmbaded(true)
    }

    const handleUploadImage = () => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection(true);
        const imgTag = `<p><br/></p><img src="${imageUrl || imageUrlText }" alt="${imageAlt}" ${imageHeight && imageWidth ? `style="height: ${imageHeight}px !important; width: ${imageWidth}px !important"` : ''} />`;
        quill.clipboard.dangerouslyPasteHTML(range.index, imgTag, 'user');
        setImageModel(false);
        setImageFile(null)
        setImageUrl(null);
        setImageAlt('');
        setImageHeight('');
        setImageWidth('');  
    }
    const handleCancel = () => {
        // Reset Image States
        setShowImageUpload(false);
        setShowImageEmbaded(false);
        setImageModel(false);
        setImageUrl(null);
        setImageFile(null);
        setImageUploadErr(false);
        setImageHeight(null);
        setImageWidth(null);
        setImageAlt('');
        setImageUrlErr(false);
        setCurrentUploadType('');
        setIsLoadingEditor(false);
        setImageUrlText('');

        // Reset Video States
        setVideoModel(false);
        setShowVideoUpload(true);
        setShowVideoEmbaded(false);
        setVideoFile(null);
        setVideoUrlText('');
        setVideoUrlErr(false);

        // Reset Attachment States
        setAttachmentFile('');
        setAttachmentFileName('');
        setAttachmentModel(false);
        setIsAttachment(false);

        // Reset Link States
        setLinkModel(false);
        setLinkTitle('');
        setLinkTarget('');
    } 
   
    function handleImageUrlChange(e)  {
        setImageUrlText(e.target.value)
        const isValid = isValidImageUrl(e.target.value)
        return !isValid ? (setImageUrlErr(true), false) : (setImageUrlErr(false), true)
    }

    const handleDrop = useCallback((event) => {
        event.preventDefault();
        if(event.dataTransfer.files[0]){
            setIsLoadingEditor(true)
            setImageFile(event.dataTransfer.files[0])
            props.uploadImages(event.dataTransfer.files[0])
            .then((res)=>{
                setIsLoadingEditor(false)
                setImageUrl(res.data.link)
            })
        }
    }, []);

    const handleFileInputChange = (event) => {
        if (event.target.files) {
            setIsLoadingEditor(true)
            setImageFile(event.target.files[0])
            let inputAccept = ['gif', 'jpeg', 'jpg', 'png']
            let maxUploadSize = 10
            let fileFormat = event.target.files[0].name.split('.').pop().toLowerCase()
            if (!inputAccept.includes(fileFormat)) {
                setAttachmentErrMsg(`Please Upload only ${inputAccept.join(',')} extension File!`)
                setImageUploadErr(true)
                setIsLoadingEditor(false)
                return
            }
            let size = event.target.files[0].size
            let fileSize = parseFloat(size / 1024 / 1024)
            if(fileSize > maxUploadSize){
                setAttachmentErrMsg(`File Size must be less than ${maxUploadSize}MB`)
                setImageUploadErr(true)
                setIsLoadingEditor(false)
                return
            }
            props.UploadImages(event.target.files[0])
            .then((res)=>{
                setIsLoadingEditor(false)
                setImageUrl(res?.data?.link)
                setImageUploadErr(false)
            })
        }
    };

    //Video Upload Functions
    const openVideoFileUpload =  () =>{
        setShowVideoUpload(true)
        setShowVideoEmbaded(false)
    }

    const openVideoUploadText =  () =>{
        setShowVideoUpload(false)
        setShowVideoEmbaded(true)
    }

    const handleVideoChange = (event) => {
        setVideoFile(event.target.files[0])
        setIsVideo(true)  
    }

    const handleVideoUpload = () => {
        const selectedFile = videoFile
        if (selectedFile) {
            let allowed = props.textEditor.video.allowed
            const fileFormat = selectedFile.name.split('.').pop().toLowerCase()
            if (!allowed.includes(fileFormat)) {
                setAttachmentErrMsg(`Please Upload only ${allowed.join(',')} extension File!`)
                setAttachmentErr(true)
                setIsVideo(false)
                return
            }
            let maxUploadSize = props?.textEditor?.video?.maxUploadSize
            let size = selectedFile.size
            let fileSize = parseFloat(size / 1024 / 1024)
            if(fileSize > maxUploadSize){
                setAttachmentErrMsg(`File Size must be less than ${maxUploadSize}MB`)
                setAttachmentErr(true)
                setIsVideo(false)
                return
            }
            setIsLoadingEditor(true)
            let fileInfo = {type: 'video', access: 'private',customInput:{}}
            props.UploadContent(selectedFile,fileInfo)
            .then((res)=>{
                setIsLoadingEditor(false)
                setAttachmentErr(false)
                const quill = quillRef.current.getEditor();
                const range = quill.getSelection(true);
                const videoTag = `<video preload="none"  poster="${iconList.defaultVideo}" src="${res.data.link}" controls ></video>`;
                quill.clipboard.dangerouslyPasteHTML(range.index, videoTag, 'user');
                setVideoModel(false);
                setVideoFile('');
                setIsLoadingEditor(false);
                setIsVideo(false)
            })
        }
    }

    const isValidVideoUrl = (url) => {
        let  regexhttps = /^http:\/\//i
        if ( regexhttps.test(url) ) {
            const regex = /^(http?\:\/\/)?(www\.)?.+\.(mp4|avi|mov|wmv|flv|mpg|mpeg|mkv|webm)$/
            return regex.test(url) 
        }  else {
            return false
        }
    }

    function  handleVideoUrlChange(e)  {
        setVideoUrlText(e.target.value)
        const isValid = isValidVideoUrl(e.target.value) // true or false
        return !isValid ? (setVideoUrlErr(true), false) : (setVideoUrlErr(false), true)
    }

    function addVideoFrmUrl() {
        setIsLoadingEditor(true)
        const isValid = isValidVideoUrl(videoUrlText) // true or false
        if(!isValid) {
            setVideoUrlErr(true)
            setIsLoadingEditor(false)
            return false
        } else {
            setVideoUrlErr(false)
        }
        const quill = quillRef.current.getEditor()
        const range = quill.getSelection(true)
        const videoTag = `<video preload="none" src="${videoUrlText}" poster="${iconList.defaultVideo}" controls></video>`
        quill.clipboard.dangerouslyPasteHTML(range.index, videoTag, 'user');
        setVideoModel(false)
        setVideoUrlText("")
        setIsLoadingEditor(false)
    }

    //Attachment Upload Functions
    const handleChangeAttachment = (event) => {
        setIsAttachment(true)
        let fileType = event.target.files[0].name.split('.').pop().toLowerCase()
        setCurrentUploadType(fileType)
        setAttachmentFile(event.target.files[0])
    }

    const handleAttachmentUpload = () => {
        if(attachmentFile){
            let fileInfo = {customInput:{},type: "attachment", access: "private"}
            if (attachmentFileName) {
                fileInfo.customInput.name = attachmentFileName
            }
            let allowed = props?.textEditor?.attachment?.allowed
            const fileFormat = attachmentFile.name.split('.').pop().toLowerCase()
            if (!allowed.includes(fileFormat)) {
                setAttachmentErrMsg(`Please Upload only ${allowed.join(',')} extension File!`)
                setAttachmentErr(true)
                setIsAttachment(false)
                return
            }
            let maxUploadSize = props.textEditor.attachment.maxUploadSize
            let size = attachmentFile.size
            let fileSize = parseFloat(size / 1024 / 1024)
            if(fileSize > maxUploadSize){
                setAttachmentErrMsg(`File Size must be less than ${maxUploadSize}MB`)
                setAttachmentErr(true)
                setIsLoadingEditor(false)
                setIsAttachment(false)
                return
            }
            setIsLoadingEditor(true)
            props.UploadContent(attachmentFile,fileInfo)
            .then((res)=>{
                setIsLoadingEditor(false)
                setAttachmentErr(false)
                const pathArray = new URL(res.data.link).pathname.split('/')
                const fileName = pathArray[pathArray.length - 1]
                const quill = quillRef.current.getEditor()
                const range = quill.getSelection(true)
                //const fileTag = `<img src="${iconList[currentUploadType]}" alt="${attachmentFileName}"/>`;
                const fileTag = `<a href="${res.data.link}" download="${fileName}" title="${attachmentFileName}" target='_blank'><img src="${iconList[currentUploadType]}"/></a>`
                // quill.clipboard.dangerouslyPasteHTML(range.index, fileTag, 'user');
                quill.insertEmbed(range.index, 'custom', fileTag, 'user');
                quill.setSelection(range.index + 1, 'silent')
                setIsAttachment(false)
                setAttachmentFile(null)
                setAttachmentModel(false)
            })
        }
    }

    //Link Upload Functions
    const handleAddLink = () => {
        const quill = quillRef.current.getEditor();
        const range = quill.getSelection(true);
        let linkHTML
         // Ensure a valid selection exists
         if (linkTarget.startsWith("http://") || linkTarget.startsWith("https://")) {
            linkHTML = `<a href="${linkTarget}" target="_blank" title="${linkTitle}">${range.length > 0 ? quill.getText(range) : linkTitle}</a>`;
        } else {
            linkHTML = `<a href="http://${linkTarget}" target="_blank" title="${linkTitle}">${range.length > 0 ? quill.getText(range) : linkTitle}</a>`;
        }
    
        quill.deleteText(range.index, range.length);
        quill.clipboard.dangerouslyPasteHTML(range.index, linkHTML, 'user');
        setLinkTitle('');
        setLinkTarget('');
        setLinkModel(false);
    };
    
    const modules = useMemo(() => ({
        history: {
            delay: 2500,
            maxStack: 50,
            userOnly: false,
        },
        toolbar: {
            container: props.toolbar,
                handlers: {
                'undo' : myUndo,
                'redo' : myRedo,
                image  : () => {
                        setImageModel(true)
                        setVideoModel(false)
                        setLinkModel(false)
                        setAttachmentModel(false)
                        showImageUploadModal()
                },
                video  : () => {
                        setImageModel(false)
                        setVideoModel(true)
                        setAttachmentModel(false)
                        setLinkModel(false)
                        openVideoFileUpload()
                },
                attachment: () => {
                        setAttachmentModel(true)
                        setImageModel(false)
                        setVideoModel(false)
                        setLinkModel(false)
                        setAttachmentFile("")
                },
                link   : () => {
                        const quill = quillRef.current.getEditor()
                        const selectedText = quill.getSelection()
                        if (selectedText && selectedText.length) {
                            setLinkTitle(quill.getText(selectedText))
                        }
                        setLinkModel(true)
                        setImageModel(false)
                        setVideoModel(false)
                        setAttachmentModel(false)
                },
                blockquote: () => {
                    const quill = quillRef.current.getEditor();
                    const format = quill.getFormat();
                    quill.format('blockquote', !format.blockquote);
                  }
                },
        },
        
  
    }), [])

    const handleChangeEditor = useCallback((newValue, delta, source, editor) => {
        if (!newValue || !delta) return
        let newValueWithHttp = newValue.replace(/\b(https?:\/\/)?(www\.\S+\.[a-zA-Z]{2,})\b/gi, (match, protocol, url) => {
            if (!protocol) {
                return 'http://' + url;
            }
            return match;
        });
        let str = newValueWithHttp?.replace(/(\r\n|\r|\n)/g, '<br>')
        setEditorValue(str)
    },[])

    useEffect(()=>{
        const QuillEditor = quillRef.current.getEditor()
        if(QuillEditor){
            QuillEditor.focus()
        }
    },[])

    useEffect(()=>{
        if(edit){
            const QuillEditor = quillRef.current.getEditor()
            if(QuillEditor){
                QuillEditor.focus()
            }
        }
    },[edit])
    
    const debouncedOnChange = useCallback(debounce((value) => {
            if (props.onChange) {
                props.onChange(value)
                // const QuillEditor = quillRef.current.getEditor()
                // if(QuillEditor){
                //     QuillEditor.focus()
                //     const range = QuillEditor.getSelection(true)
                //     const length = QuillEditor.getLength()
                //     if(range?.length === 0 && range.index > 0){
                //         QuillEditor.setSelection(range.index, range.length)
                //     }else{
                //         QuillEditor.setSelection(length,length)
                //     }
                    
                // }
            }
        }, 300),
        [props.onChange]
    )

    useEffect(() => {
        if (editorValue) {
            debouncedOnChange(editorValue)
        }
    }, [editorValue])
    
    const handleChangeFocus = (range, source, editor) => {
        if(props.onChange){
            props.onChange(editorValue)
        }
    }

  return (<>
    <Box style={{height:'280px',position:'relative'}} ref={editorRef}>
        {!edit && (
            <ReactQuill 
                ref={quillRef}
                value={editorValue}
                theme='snow'
                placeholder='Enter Text'
                modules={modules}
                id='editor'
                style={{height: '220px',position:'absolute',width:'100%'}}
                onChange={handleChangeEditor}
                onFocus={handleChangeFocus}
            />)
        }

        {edit && (
            <ReactQuill 
                ref={quillRef}
                value={editorValue}
                theme='snow'
                placeholder='Enter Text'
                modules={modules}
                id='editor'
                style={{height: '220px',position:'absolute',width:'100%'}}
                onChange={handleChangeEditor}
                onFocus={handleChangeFocus}
            />
        )}       
    </Box>
    {imageModel && 
                <Box ref={imageModalRef}>
                    <Box style={{zIndex:'1000',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',backgroundColor:'white',position:'absolute',top:'14%',left:'48%',padding:'5px',width:'235px',height: 'auto'}}>
                        <Box>
                            <Tabs>
                                <TabList display={'flex'} justifyContent={'space-evenly'}>
                                    <Tab onClick={openImageFileUpload}>File Upload</Tab>
                                    <Tab onClick={openImageUploadText}>URL</Tab>
                                </TabList>
                            <TabPanels>
                                <TabPanel>
                                    {showImageUpload &&
                                        <Box>
                                            {!imageUrl &&
                                                <Box onClick={() => document.querySelector('input[type="file"]').click()} backgroundColor={'#f1f1f1'} padding={'5px'} marginBottom={'10%'} position={'relative'}>
                                                    {isLoadingEditor && (<Spinner position="absolute" top="30%" left="45%" transform="translate(-50%, -50%)" zIndex={'1000'}/>)}
                                                    <Box
                                                        style={{
                                                        border: '2px dashed black',
                                                        padding: '10px 0px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        display: 'block',
                                                        marginTop:'1%',
                                                        width:'100%'}}
                                                        onDrop={handleDrop}
                                                        onDragOver={(e) => e.preventDefault()}
                                                    >
                                                        <Input
                                                            type="file"
                                                            accept="image/gif,image/jpeg,image/jpg,image/png"
                                                            style={{ display: 'none' }}
                                                            onChange={handleFileInputChange}
                                                            ref={(input) => input && (input.value = '')}
                                                        />
                                                        <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <Button  variant="link" color="black" marginRight="1%" sx={{ textDecoration: 'none', fontWeight: 'normal', _hover: { textDecoration: 'none' },fontSize:'12px' }} isDisabled={isLoadingEditor}>
                                                                Drop the file or click to upload
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            }
                                            
                                            {imageUrl && 
                                                <Box padding={'5px'} marginBottom={'10%'} 
                                                    style={{
                                                        border: '2px dashed black',
                                                        padding: '10px 0px',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        display: 'block',
                                                        marginTop:'1%',
                                                        width:'100%'
                                                    }}
                                                >
                                                    <Box>
                                                        <img src={imageUrl} height="180px" width="150px" />
                                                    </Box>
                                                </Box>
                                            }

                                            {imageUploadErr &&
                                                <Box className="error" color={'red'}>{attachmentErrMsg}</Box>}
                                                    <Box marginBottom={'10%'}>
                                                        <Box style={{ display: 'flex', fontSize: '12px', alignItems: 'center', justifyContent: 'space-between',marginBottom:'10%' }}>
                                                            <Text style={{ marginRight: '5px' }}>Alt&nbsp;Text</Text>
                                                        <Box>
                                                        <Input
                                                            type='text'
                                                            name='alt'
                                                            placeholder='alt'
                                                            fontSize='12px'
                                                            height='13px'
                                                            padding={0}
                                                            style={{ border: '1px solid #d3d3d3',width:'100%' }}
                                                            onChange={(e)=>setImageAlt(e.target.value)}
                                                            value = {imageAlt}
                                                        />
                                                    </Box>
                                                                        
                                                </Box>
                                                {props?.pixels && (<Box display={'flex'} alignItems={'center'}>↕&nbsp;
                                                    <Input 
                                                        type='number'
                                                        name='height'
                                                        placeholder='auto'
                                                        fontSize='12px'
                                                        height='13px'
                                                        padding={0}
                                                        value = {imageHeight}
                                                        style={{ border: '1px solid #d3d3d3' }}
                                                        onChange={(e)=>setImageHeight(e.target.value)}
                                                    /> 
                                                    &nbsp;
                                                    <Text style={{color:'red'}}>*</Text>
                                                    &nbsp;↔&nbsp;
                                                    <Input 
                                                        type='number'
                                                        name='width'
                                                        placeholder='auto'
                                                        fontSize='12px'
                                                        height='13px'
                                                        padding={0}
                                                        value={imageWidth}
                                                        style={{ border: '1px solid #d3d3d3' }}
                                                        onChange={(e)=>setImageWidth(e.target.value)}
                                                    />
                                                    &nbsp;
                                                    <Text style={{color:'red'}}>*</Text>
                                                </Box>
                                                )}
                                                </Box>
                                                    <Box display={'flex'} justifyContent={'center'} alignItems={"center"}>
                                                        <Button backgroundColor={'white'} border={'1px solid #d3d3d3'} onClick={handleUploadImage} style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} disabled={!imageFile}>Add </Button>
                                                        <Button backgroundColor={'white'} border={'1px solid #d3d3d3'} style={{marginLeft:'10px',backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} onClick={handleCancel}>Cancel</Button>
                                                    </Box> 
                                                </Box>
                                    }
                                    </TabPanel>
                                    <TabPanel>
                                        {showImageEmbaded &&
                                            <Box>
                                                {/* <input value={imageUrlText}   onChange={handleImageUrlChange} placeholder='Enter Image Url'   className="videoUrlTextBox"  type="text" name="myFileText"/> */}
                                                <Input 
                                                    onChange={handleImageUrlChange}
                                                    type='text'
                                                    name='width'
                                                    placeholder=''
                                                    fontSize='16px'
                                                    padding={0}
                                                    value={imageUrlText}
                                                    style={{ border: '1px solid #d3d3d3',marginBottom:'10%',height:'40px' }}
                                                />
                                                {imageUrlErr && imageUrlText && <div className="error" style={{ color: 'red' }}>Invalid image url. Please enter valid a secure url.</div>}
                                                <Box marginBottom={'10%'}>
                                                    <Box style={{ display: 'flex', fontSize: '12px', alignItems: 'center', justifyContent: 'space-between',marginBottom:'10%' }}>
                                                        <Text style={{ marginRight: '5px' }}>Alt&nbsp;Text</Text>
                                                    <Box>
                                                    <Input
                                                        type='text'
                                                        name='alt'
                                                        placeholder='alt'
                                                        fontSize='12px'
                                                        height='13px'
                                                        padding={0}
                                                        value={imageAlt}
                                                        style={{ border: '1px solid #d3d3d3',width:'100%' }}
                                                        onChange={(e)=>setImageAlt(e.target.value)}
                                                    />
                                                </Box>
                                            </Box>
                                            {props?.pixels && (
                                                <Box display={'flex'} alignItems={'center'}>
                                                ↕&nbsp;
                                                <Input 
                                                    type='number'
                                                    name='height'
                                                    placeholder='auto'
                                                    fontSize='12px'
                                                    height='13px'
                                                    padding={0}
                                                    value={imageHeight}
                                                    style={{ border: '1px solid #d3d3d3' }}
                                                    onChange={(e)=>setImageHeight(e.target.value)}
                                                /> 
                                                &nbsp;
                                                <Text style={{color:'red'}}>*</Text>
                                                &nbsp;↔&nbsp;
                                                <Input 
                                                    type='number'
                                                    name='width'
                                                    placeholder='auto'
                                                    fontSize='12px'
                                                    height='13px'
                                                    padding={0}
                                                    value={imageWidth}
                                                    style={{ border: '1px solid #d3d3d3' }}
                                                    onChange={(e)=>setImageWidth(e.target.value)}
                                                />
                                                &nbsp;
                                                <Text style={{color:'red'}}>*</Text>
                                            </Box>
                                            )}
                                            
                                            </Box>
                                                <Box display={'flex'} justifyContent={'center'} alignItems={"center"}>
                                                    <Button backgroundColor={'white'} border={'1px solid #d3d3d3'} onClick={handleUploadImage} style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} disabled={!imageUrlText || imageUrlErr}>Add </Button>
                                                    <Button backgroundColor={'white'} border={'1px solid #d3d3d3'} style={{marginLeft:'10px',backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} onClick={handleCancel}>Cancel</Button>
                                                </Box> 
                                            </Box>
                                        } 
                                    </TabPanel>
                                </TabPanels>
                            </Tabs>
                        </Box>
                    </Box>
                </Box>
            }
    {videoModel && 
            <Box ref={videoModalRef}>
                <Box style={{zIndex:'1000',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',backgroundColor:'white',position:'absolute',top:'14%',left:'50%',padding:'5px',width:'235px',height:'auto'}}>
                    <Box >
                        <Tabs>
                            <TabList display={'flex'} justifyContent={'space-evenly'}>
                                <Tab onClick={openVideoFileUpload}>File Upload</Tab>
                                <Tab onClick={openVideoUploadText}>URL</Tab>
                            </TabList>
                            <TabPanels>
                            <TabPanel>
                                {showVideoUpload &&
                                    <Box>
                                        <Box onClick={() => document.querySelector('input[type="file"]').click()} backgroundColor={'#f1f1f1'} padding={'5px'} marginBottom={'10%'} position={'relative'}>
                                            {isLoadingEditor && (<Spinner position="absolute" top="30%" left="45%" transform="translate(-50%, -50%)" zIndex={'1000'}/>)}
                                            <Box
                                                style={{
                                                border: '2px dashed black',
                                                padding: '10px 0px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                display: 'block',
                                                marginTop:'1%',
                                                width:'100%'}}
                                            >
                                                <Input
                                                    type="file"
                                                    accept="video/mp4"
                                                    style={{ display: 'none' }}
                                                    onChange={handleVideoChange}
                                                    ref={(input) => input && (input.value = '')}
                                                />
                                                {!isVideo && 
                                                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <Button  variant="link" color="black" marginRight="1%" sx={{ textDecoration: 'none', fontWeight: 'normal', _hover: { textDecoration: 'none' },fontSize:'12px' }} isDisabled={isLoadingEditor}>
                                                            click to upload
                                                        </Button>
                                                    </Box>
                                                }
                                                {isVideo && <Text color={isLoadingEditor ? "gray !important" : undefined}>{videoFile.name}</Text> }                   
                                            </Box>
                                        </Box>
                                        {attachmentErr && <Box color={'red'}>{attachmentErrMsg}</Box>}
                                        <Box display={'flex'} justifyContent={'center'} alignItems={"center"}>
                                            <Button onClick={handleVideoUpload} type="button" style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} disabled={!videoFile || !isVideo}>Upload</Button>
                                        </Box>
                                    </Box>
                                }
                                </TabPanel>
                                <TabPanel>
                                {showVideoEmbaded &&
                                    <Box padding={'15px 5px 15px 0px'}>
                                        <Box marginBottom={'10%'} display={'flex'}>
                                            <Input value={videoUrlText}  onChange={handleVideoUrlChange} type="text" name="myFileText" width={'100%'}/>
                                            <Text style={{color:'red'}}>*</Text>
                                        </Box>
                                        {/* {videoUrlErr && <div>Invalid video url. Please enter valid secure url .</div>} */}
                                        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
                                            <Button onClick={addVideoFrmUrl} style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} disabled={!videoUrlText}>Add</Button>
                                            <Button style={{marginLeft:"10px",backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} onClick={handleCancel}>Cancel</Button>
                                        </Box>
                                    </Box>
                                }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Box>
            </Box>}
    
    {attachmentModel && (
                <Box ref={attachmentModalRef}>
                    <Box style={{zIndex:'1000',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',backgroundColor:'white',position:'absolute',top:'14%',left:'52.5%',padding:'5px',width:'235px',height:'auto'}}>
                    <Box >
                        <Tabs>
                            <TabList>
                                <Tab>File Upload</Tab>
                            </TabList>
                            <TabPanels>
                                <TabPanel>
                                    <Box>
                                        <Box  backgroundColor={'#f1f1f1'} padding={'5px'} marginBottom={'10%'} position={'relative'}>
                                        {isLoadingEditor && (<Spinner position="absolute" top="30%" left="45%" transform="translate(-50%, -50%)" zIndex={'1000'}/>)}
                                            <Box
                                                style={{
                                                border: '2px dashed black',
                                                padding: '10px 0px',
                                                textAlign: 'center',
                                                cursor: 'pointer',
                                                display: 'block',
                                                marginTop:'1%',
                                                width:'100%'
                                                }}
                                            >
                                                <Input
                                                    type="file"
                                                    accept="application/*,text/plain"
                                                    style={{ display: 'none' }}
                                                    onChange={handleChangeAttachment}
                                                    ref={(input) => input && (input.value = '')}
                                                />
                                                {!isAttachment && 
                                                <Box onClick={() => document.querySelector('input[type="file"]').click()} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <Button  variant="link" color="black" marginRight="1%" sx={{ textDecoration: 'none', fontWeight: 'normal', _hover: { textDecoration: 'none' },fontSize:'12px' }} disabled={isLoadingEditor}>
                                                        click to upload
                                                    </Button>
                                                </Box>}
                                                
                                                {isAttachment && <Text color={isLoadingEditor ? "gray !important" : undefined}>{attachmentFile?.name}</Text>}
                                                {/* {uploadedFileName && <span>{uploadedFileName}</span>} */}            
                                            </Box>
                                        </Box>
                                        {attachmentErr && <Box color={'red'}>{attachmentErrMsg}</Box>}
                                        <Box marginBottom={'10%'}>
                                            <Text style={{ marginRight: '5px' }}>File&nbsp;Name</Text>
                                            <Box>
                                                <Input
                                                    type='text'
                                                    name='alt'
                                                    placeholder='Enter File Name'
                                                    fontSize='12px'
                                                    height='13px'
                                                    padding={0}
                                                    style={{width:'100%' }}
                                                    onChange={(e)=>setAttachmentFileName(e.target.value)}
                                                />
                                            </Box>
                                        </Box>    
                                        <Box display={'flex'} justifyContent={'center'} alignItems={"center"}>
                                            <Button onClick={handleAttachmentUpload} type="button" style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} disabled={!attachmentFile}>Upload</Button>
                                        </Box>
                                    </Box>
                                </TabPanel>
                                <TabPanel>
                                {showVideoEmbaded &&
                                    <Box>
                                        <Input value={videoUrlText} placeholder='Enter Video Url'  onChange={handleVideoUrlChange} type="text" name="myFileText" />
                                        <Box></Box>
                                        {videoUrlErr && <div>Invalid video url. Please enter valid secure url .</div>}
                                        <Button onClick={addVideoFrmUrl} style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}}>Submit</Button>
                                        <Button style={{marginLeft:"10px",backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} onClick={handleCancel}>Cancel</Button>
                                    </Box>
                                }
                                </TabPanel>
                            </TabPanels>
                        </Tabs>
                    </Box>
                </Box>
            </Box>)}
    
    {linkModel && 
            <Box ref={linkModalRef}>
                <Box style={{zIndex:'1000',boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',backgroundColor:'white',position:'absolute',top:'14%',left:'45%',padding:'15px',width:'235px',height:'auto'}}>
                    <Box marginBottom={'10%'}>
                        <Text style={{ marginBottom: '5px' }}>Link&nbsp;Title</Text>
                            <Box>
                                <Input
                                    type='text'
                                    name='alt'
                                    fontSize='12px'
                                    height='15px'
                                    padding={0}
                                    style={{ border: '1px solid #d3d3d3',width:'100%' }}
                                    onChange={(e)=>setLinkTitle(e.target.value)}
                                    value={linkTitle}
                                />
                            </Box>
                    </Box>
                    <Box marginBottom={'10%'}>
                        <Text style={{ marginBottom: '5px' }}>Link&nbsp;Target</Text>
                            <Box>
                                <Input
                                    type='text'
                                    name='alt'
                                    fontSize='12px'
                                    height='15px'
                                    padding={0}
                                    style={{ border: '1px solid #d3d3d3',width:'100%' }}
                                    onChange={(e)=>setLinkTarget(e.target.value)}
                                    value={linkTarget}
                                />
                            </Box>
                    </Box>
                    <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                        <Button onClick={handleAddLink} style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal'}} disabled={!linkTarget}>Add</Button>
                        <Button style={{backgroundColor:'white',border:'1px solid #d3d3d3',fontWeight:'normal',marginLeft:"10px"}} onClick={handleCancel}>Cancel</Button>
                    </Box>
                </Box>
            </Box>}
    </>)
    
}

export {QuillEditor}
